<template>
  <section class="profile content-wrapper">
    <!-- bug be ity tsy mety miala ilay loader -->
      <!-- <pageLoader v-show="ploader" :key="key" /> -->
    <div v-if="!isAuthenticated">
        <unauthenticated />
    </div>
    <!-- si connecté -->
    <div v-else>
      <div class="page-header">
        <h3 class="page-title">
          Profil
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);"></a></li>
            <li class="breadcrumb-item active" aria-current="page">Profil</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="border-bottom text-center pb-4">
                    <img src="../assets/images/faces/face12.jpg" alt="profile" class="img-lg rounded-circle mb-3"/>
                    <p>Bio ou citation de l'utilisateur </p>
                    <h3>{{myInfos.pseudo}}</h3>
                    <div class="d-block d-sm-flex justify-content-between">
                    </div>
                  </div>
                  <div class="py-4" v-if="myInfos.address">
                    <p class="clearfix">
                      <span class="float-left">
                        Email
                      </span>
                      <span class="float-right text-muted">
                        {{myInfos.email}}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left">
                        Voie
                      </span>
                      <span class="float-right text-muted">
                        {{myInfos.address.Voie}}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left">
                        Ville
                      </span>
                      <span class="float-right text-muted">
                        {{myInfos.address.Ville}}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left">
                        Département
                      </span>
                      <span class="float-right text-muted">
                        {{myInfos.address.Département}}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left">
                        Pays
                      </span>
                      <span class="float-right text-muted">
                        <a>{{myInfos.address.Pays}}</a>
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left">
                        Code Postal
                      </span>
                      <span class="float-right text-muted">
                        <a>202</a>
                      </span>
                    </p>
                  </div>
                  <b-button variant="primary btn-block">Informations</b-button>
                </div>
                <div class="col-lg-8">
                  <div class="d-lg-flex justify-content-between">
                    <div>
                      <h3>HELLO</h3>
                      <h3 class="mt-3 mt-lg-0">{{myInfos.firstname}} {{myInfos.lastname}}</h3>
                      <div class="d-flex align-items-center">
                        <h5 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">{{$store.state.pseudo}}</h5>
                      </div>
                    </div>
                    <div class="mt-3 mt-lg-0">
                      <b-button variant="outline-secondary btn-icon">
                        <i class="mdi mdi-comment-processing"></i>
                      </b-button>
                      <b-button variant="primary" :to="{ name: 'profilUpdate'}">Modifier le Profil</b-button>
                    </div>
                  </div>
                  <div class="mt-4 py-2 border-top border-bottom">
                    <ul class="nav profile-navbar">
                      <li class="nav-item">
                        <a class="nav-link active" href="javascript:void(0);">
                          <i class="mdi mdi-newspaper"></i>
                          Mes publications
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);">
                          <i class="mdi mdi-calendar"></i>
                          <router-link to="/BonDeCommande">
                            Mes Achats
                          </router-link>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);">
                          <i class="mdi mdi-attachment"></i>
                          <router-link to="/facture">
                            Mes factures
                          </router-link>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="profile-feed">
                    <div class="d-flex align-items-start profile-feed-item" v-for="(rating,i) in ratings" :key="i">
                      <img src="../assets/images/faces/face12.jpg" alt="profile" class="img-sm rounded-circle"/>
                      <div class="ml-4">
                        <h6>
                          {{myInfos.firstname}} {{myInfos.lastname}}
                          <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>{{rating.datepublished}}</small>
                        </h6>
                        <p>
                          {{rating.comment}}
                        </p>
                        <p class="small text-muted mt-2 mb-0">
                          <span>
                            <i class="mdi mdi-star mr-1"></i>{{rating.rating}}
                          </span>
                          <span class="ml-2">
                            <i class="mdi mdi-comment mr-1"></i>11
                          </span>
                          <span class="ml-2">
                            <i class="mdi mdi-reply"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require('axios').default
export default {
  name: 'profile',
  data () {
    return {
      // key pour reloader le composant
      key:1,
      ratings: [],
      myInfos: {},
      isAuthenticated:false,
      ploader:false
    }
  },
  methods:{
    myinfos(){
      axios.get(`myinfos`)
      .then(resMyInfo => {
        this.ploader= false;
        this.isAuthenticated = true
        this.myInfos = resMyInfo.data
        console.log(resMyInfo);
      })
      .catch(errMyInfo=>{
        this.ploader=false;
        this.isAuthenticated = false
        console.log(errMyInfo);
      })
    }
  },
  mounted () {
    this.myinfos()
  },
}
</script>

<style scoped>
 a,a:hover {
  color: #4b5156;
  text-decoration: none
}
</style>